.App-header {
  background-color: #f2f8fe;
  min-height: 100vh;
  flex-direction: row;
  color: black !important;
  padding: 50px;
  text-align: center;
  justify-content: center;
}

.toggle_button {
  border-radius: 50px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px
}

.selected_button {
  background: 'white';
  color: '#1976d2';
}

.rbc-today {
  background-color: white !important;
}

.rbc-event-content {
  height: 48px !important;
  text-align: center;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #63A02F !important;
}

.rbc-event:hover {
  background-color: #0288d1 !important;
}

.rbc-day-slot:hover {
  background-color: #0288d1 !important;
}

.rbc-background-event:hover { 
  background-color: #0288d1 !important;
}


input[type="text"], textarea {
  background-color : white; 
}

@media screen and (max-width: 600px) {
  .App-header {
    padding: 20px !important;
  }
  .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    font-size: 11px !important;
  }
}
