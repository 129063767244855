.slick-prev:before, .slick-next:before {
  font-size: 30px !important;
}

.slick-prev {
    left: -35px !important;
}

.slick-arrow.slick-next:before {
  color:#0288d1;
}

.slick-arrow.slick-prev:before {
  color:#0288d1;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}
.slick-slide > :first-child {
  height: 100% !important;
  display: flex;
}

.slick-list
{
  padding:10px !important;
}

.package {
  height: 100% !important;
}
.service,.selected_service {
  height: 100% !important;
}

.caption {
  display: block;
  text-align: center;
}

.selected_car_type {
  background: white;
  cursor: pointer;
  border: 1px solid #ececec;
  color: #0288d1;
  margin: 20px;
  padding: 10px;
}

.car_type {
  cursor: pointer;
  object-fit: contain;
  background: #f2f8fe;
  border: 1px solid transparent;
  margin: 20px;
  padding: 10px;
}

.car_type:hover {
  background: white;
  cursor: pointer;
  border: 1px solid #ececec;
  color: #0288d1;
  margin: 20px;
  padding: 10px;
}

.car_image {
  height: 150px;
  width: 220px;
  object-fit: contain;
}

.packages_div {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: 10px;
  overflow-x: auto;
}

.package {
  background: white !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 5px 10px 18px #888888;
}

.selected_package {
  background: gray !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  min-width: 250px;
}

.service {
  background: white !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 5px 10px 18px #888888;
}

.selected_service {
  background: white !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 5px 10px 18px #888888;
  border-radius: 10px;
  border: 3px solid #0288d1 !important;
}

.selected_date {
  background: #0288d1;
  padding: 11px;
  border-radius: 14px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  min-height: 92px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.upper_div_date {
  height: 92px;
  line-height: 92px;
  text-align: center;
}

.PhoneInputInput {
  height: 35px !important;
  border: none !important;
  border-bottom: 1px solid black !important;
}

.phone_number {
  margin-left: 20px;
}

.get_details {
  margin-left: 20px !important;
  height: 40px;
  margin-top: 13px;
}

.calendar_div {
  width: 50%;
  padding: 20;
  margin: 0 auto;
  position: inherit;
}

.customer_type {
  border-radius: 10px;
  border: 3px solid transparent;
}

.selected_customer_type {
  border-radius: 10px;
  border: 3px solid #0288d1 !important;
}

.slots {
  background: #63a02f;
  color: white;
}

.slots:hover {
  background: #0288d1;
}

.center {
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .car_image {
    height: 120px !important;
    width: 120px !important;
    object-fit: "contain";
  }

  .selected_car_type {
    width: 120px;
  }

  .car_type {
    width: 120px;
    margin: 12px;
  }

  .calendar_div {
    width: 100%;
    padding: 20;
  }

  .phone_number {
    margin-left: 0px;
  }

  .get_details {
    margin-left: 0px;
    height: 40px;
    margin-top: 13px;
  }

  .center {
    justify-content: flex-start;
  }

  .packages_div {
    display: flex;
    flex-direction: row;
    padding: 20px;
    overflow: auto !important;
    flex-wrap: nowrap;
    justify-content: left;
  }
}
